import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";

// components
import "./styles/components/border.scss";
import "./styles/components/button.scss";
import "./styles/components/text.scss";
import "./styles/components/input.scss";

// widget styles
import "./styles/widgets/email-widget.scss";
import "./styles/widgets/footer-widget.scss";
import "./styles/widgets/header-widget.scss";

import "./styles/pages/landing-page-styles.scss";
import "./styles/pages/about-page-styles.scss";

import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
