import { useMedia } from "react-use";

export const useBreakpoints = () => {
  const xs = useMedia("(min-width: 0px)");
  const sm = useMedia("(min-width: 576px)");
  const md = useMedia("(min-width: 768px)");
  const lg = useMedia("(min-width: 992px)");
  const xxl = useMedia("(min-width: 1400px)");

  return { xs, sm, md, lg, xxl };
};
