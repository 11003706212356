import { Col, Row } from "react-bootstrap";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { useEffect, useRef } from "react";
import { Break } from "../../components/Break";
import { SocialMedia } from "../../components/SocialMedia";
import { handleScrollAbout, setHomeRef } from "../../constants/referance";
import { AnimationButton } from "../../components/AnimationButton";

export const LogoSection = () => {
  const { lg, md, sm, xs, xxl } = useBreakpoints();

  return (
    <>
      <Row
        ref={(ref) => setHomeRef(ref)}
        className="logo-gif-container justify-content-center "
      >
        <img className="logo-gif" src={"assets/gif/logo.gif"} />
        <img
          className="background-logo"
          src={"assets/svg/background-logo.svg"}
        />

        <div className="logo-text-1 d-flex flex-column justify-content-between align-items-center">
          <img src="assets/img/TetlabsBlockChain-1.png" />
        </div>

        <Col xs={0} lg={7} />

        {!lg && (
          <Col xs={12}>
            <div className="logo-text-3-container d-flex justify-content-center flex-column align-items-center text-center">
              <div className="logo-text-3 ">
                Leads the Way Towards a More Efficient and Trustworthy Future.
              </div>

              <Break />
              <AnimationButton onClick={handleScrollAbout}>
                Learn More
              </AnimationButton>
              <Break />
            </div>
          </Col>
        )}

        {lg && (
          <Col xs={12} lg={5}>
            <div className="logo-text-3-container  d-flex justify-content-center flex-column align-items-start text-center">
              <div className="logo-text-3">Leads the Way Towards a More</div>
              <div className="logo-text-3">
                Efficient and Trustworthy Future.
              </div>
              <Break height={24} />
              {/* <div className="gradient-box button" onClick={handleScrollAbout}>
                Learn More
              </div> */}
              <AnimationButton onClick={handleScrollAbout}>
                Learn More
              </AnimationButton>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};
