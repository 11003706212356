import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { useRef, useState } from "react";

export const WelcomeSvg = () => {
  return (
    <div>
      <motion.svg
        width="100%"
        height="100%"
        viewBox="0 0 1209 515"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <motion.svg
          x={-65}
          y={-35}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 3, delay: 0.5 } }}
        >
          <motion.rect
            x="475"
            y="403.631"
            width="1"
            height="59"
            fill="white"
            fillOpacity="0.5"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 3, delay: 0.5 } }}
          />

          <text x="500" y="415" fill="#959595">
            Embark on a journey with TetLabs, a gathering of passionate
            blockchain
          </text>
          <text x="500" y="440" fill="#959595">
            enthusiasts. We're here to unravel the limitless potential of
            blockchain
          </text>
          <text x="500" y="465" fill="#959595">
            technology, reshaping industries and creating new horizons.
          </text>

          <text x="428" y="316" fill="#FFFFFF" fontSize={50} fontWeight={600}>
            Welcome to
          </text>

          <text x="745" y="316" fill="#4173FD" fontSize={50} fontWeight={600}>
            TetLabs
          </text>
          <text x="428" y="355" fill="#FFFFFF" fontSize={25}>
            Exploring the Infinite Horizons of Blockchain Technology
          </text>
        </motion.svg>

        <mask
          id="mask0_315_379"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="1152"
          y="458"
          width="39"
          height="39"
        >
          <motion.path
            d="M1165.33 481.093V495.289C1165.65 497.193 1167.39 496.513 1167.39 496.513L1176.47 494.107C1177.88 492.988 1177.72 491.983 1177.72 491.983L1177.77 477.661L1165.33 481.072V481.093ZM1153.54 468.235C1153.26 468.361 1153.14 468.57 1153.14 468.57C1153.07 468.716 1153.05 468.852 1153.05 468.905C1153 469.773 1152.91 476.217 1152.89 477.264C1152.84 479.628 1152.86 481.501 1152.94 482.965C1152.94 482.965 1153.54 484.534 1155.06 483.917C1156.65 483.478 1160.87 482.317 1165.32 481.103V464.908C1158.72 466.718 1153.89 468.078 1153.54 468.235ZM1187.65 458.757C1187.39 458.83 1183.18 459.991 1177.77 461.477V477.682L1188.09 474.847C1188.09 474.847 1189.88 474.282 1190.1 472.608V460.096C1190.1 460.096 1189.98 458.631 1188.48 458.631C1188.24 458.631 1187.97 458.673 1187.66 458.757"
            fill="white"
            initial={{ rotateY: 0 }}
            animate={{
              rotateY: 1080,
              transition: {
                duration: 1.5,
                ease: "easeOut",
                repeatDelay: 3,
                repeat: Infinity,
              },
            }}
          />
        </mask>
        <g mask="url(#mask0_315_379)">
          <path
            d="M1190.09 458.631H1152.84V497.193H1190.09V458.631Z"
            fill="#4173FD"
          />
        </g>

        <motion.path
          d="M55 490.734V161.529C55 154.958 60.3267 149.631 66.8975 149.631H1187.1C1193.67 149.631 1199 154.958 1199 161.529V400.082C1199 412.784 1188.7 423.082 1176 423.082H1164.16C1137.71 423.082 1116.26 444.527 1116.26 470.982V479.631C1116.26 492.334 1105.96 502.631 1093.26 502.631H331.5H66.8975C60.3267 502.631 55 497.305 55 490.734Z"
          stroke="url(#paint0_linear_315_379)"
          strokeWidth="2"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1, transition: { duration: 2 } }}
        />
        <motion.circle
          cx="1171"
          cy="476.631"
          r="37.339"
          stroke="#4173FD"
          strokeWidth="1.32194"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1, transition: { duration: 2 } }}
        />

        <motion.svg
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 3,
              delay: 0.5,
            },
          }}
        >
          <motion.image
            id="image1_315_379"
            width="370"
            height="315"
            x={20}
            y={20}
            href="assets/img/about/logo-metal-2.png"
            // initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              y: [0, -20],
              transition: {
                duration: 3,
                repeatType: "mirror",
                delay: 2,
                repeat: Infinity,
              },
            }}
          />
        </motion.svg>

        <motion.image
          id="image0_315_379"
          width="284"
          height="164"
          x={60}
          y={336}
          href="assets/img/about/logo-metal-1.png"
          initial={{ opacity: 0, y: +100 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { duration: 2 },
          }}
        />

        <defs>
          <linearGradient
            id="paint0_linear_315_379"
            x1="55"
            y1="326.13"
            x2="1199"
            y2="326.13"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#32D5FD" />
            <stop offset="1" stopColor="#4173FD" />
          </linearGradient>
        </defs>
      </motion.svg>
    </div>
  );
};
