import { Col, Row } from "react-bootstrap";
import { Break } from "../../components/Break";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { setServiceRef } from "../../constants/referance";

const data = [
  {
    id: "service_blockchain",
    title1: "Blockchain ",
    title2: "& Crypto Solutions",
    description:
      "Experience premier blockchain app development. Our experts tailor robust dApps using Hyperledger, Solidity, Cosmos, and more. Elevate efficiency and workflow with TetLabs' decentralized solutions. ",
    img: "assets/img/blockchain.png",
    imgOrder: "last",
  },
  {
    title1: "Fintech",
    title2: "& Banking Solutions",
    description:
      "Innovative Financial Services for the Digital Age Revolutionize your financial operations with our Fintech and blockchain solutions. Streamline processes, ensure transparency, and offer personalized services to customers.",
    img: "assets/img/fintech.png",
    imgOrder: "first",
  },
  {
    title1: "Crypto Coin",
    title2: "& Tokenization",
    description:
      "Elevate Your Business with Custom Crypto Tokens Enter the crypto market with our expert crypto coin and tokenization services. Craft bespoke tokens and coins, leveraging advanced protocols and strategies for success.",
    img: "assets/img/bitcoin.png",
    imgOrder: "last",
  },
];

export const ServiceSection = () => {
  const { lg, md, sm, xs, xxl } = useBreakpoints();
  return (
    <div className="service" ref={(ref) => setServiceRef(ref)}>
      <Row>
        <Col lg={{ span: 12, offset: 0 }} xs={{ span: 11, offset: 1 }}>
          <div className="head">Services</div>
        </Col>
      </Row>
      <Break height={lg ? 128 : 24} />
      {data.map((item, index) => (
        <Row key={index} id={item?.id}>
          <Col
            lg={{ order: item.imgOrder, span: 6 }}
            xs={{ span: 12, order: "first" }}
          >
            <img
              src={item.img}
              style={{
                backgroundImage: `url(${"assets/svg/background-logo.svg"})`,
              }}
            />
          </Col>

          <Col lg={{ span: 6, offset: 0 }} xs={{ span: 11, offset: 1 }}>
            <div className="content">
              <div className="sub-head-2">{item.title1}</div>
              <div className="sub-head-2">{item.title2}</div>
              <Break />
              <div className="d-flex">
                <div className="text-column" />
                <div className="justify-text gray-text body-default-text">
                  {item.description}
                  <Break />
                  {/* <div className="button-solid">Learn More</div> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
};
