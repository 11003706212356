import { Col, Row } from "react-bootstrap";
import { useBreakpoints } from "../hooks/useBreakpoints";
import { Break } from "../components/Break";
import { SocialMedia } from "../components/SocialMedia";
import { contactRef, setContactRef } from "../constants/referance";
import { AnimationButton } from "../components/AnimationButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../helper/api";

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
  subject: yup.string().required(),
  message: yup.string().required().max(250).min(5),
  phone: yup.string().required().max(20).min(2),
});

export const EmailWidget = () => {
  const [loading, setLoading] = useState(false);
  const { lg } = useBreakpoints();
  const { values, handleChange, handleSubmit, errors, setValues } = useFormik({
    initialValues: { email: "", subject: "", message: "", phone: "" },
    validationSchema,
    validateOnChange: false,

    onSubmit: async (params) => {
      setLoading(true);

      try {
        api
          .post("api/guest/opentetlabsticket", {
            ...params,
          })
          .then((res) => {})
          .finally(() => {
            setValues({
              email: "",
              message: "",
              subject: "",
              phone: "",
            });

            toast.success("Thank you for your email.");
            setLoading(false);
          });
      } catch (err) {}
    },
  });

  const handleErrors = () => {
    validationSchema.validate(values).catch((errors) => {
      if (errors.message) toast.error(errors.message);
      else if (errors.subject) toast.error(errors.subject);
      else if (errors.email) toast.error(errors.email);
      else if (errors.phone) toast.error(errors.phone);
      toast.clearWaitingQueue();
    });
  };
  // useEffect(() => {
  //   let message = "";
  //   for (const error in errors) {
  //     let err = errors[error];

  //     message = message + " " + err;
  //   }

  //   if (message != "") toast.error(message);
  // }, [errors]);
  return (
    <div id="email-widget" ref={(ref) => setContactRef(ref)}>
      <Row>
        <Col lg={{ span: 6, order: "first" }} xs={{ span: 12, order: "last" }}>
          {!lg && <Break />}
          <div className="content head-2 text-center text-lg-start">
            Unleash Your Vision Reach Out to Tetlabs
          </div>
          <Break />
          <Row
            style={{
              display: "flex",
              justifyContent: lg ? "flex-start" : "center",
            }}
          >
            <Break/>
            <Col xs={12}>
              <b>Or Give us a Visit At</b>
            </Col>
            <Col xs={12}>
              <i>Concord Tower, Media city, Dubai- UAE PRIVATE OFFICE</i>
              <br/>
              <i>
                F9 <br />
                Office 908
              </i>
              <br />
              <br />
              <br />
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: lg ? "flex-start" : "center",
            }}
          >
            <SocialMedia />
          </div>
        </Col>

        <Col lg={6}>
          <div className="box">
            <div className={`input ${errors.email && "error"}`}>
              <input
                className="body-default-text"
                value={values.email}
                onChange={handleChange("email")}
                placeholder="Email"
                type="text"
              />
            </div>
            <div className={`input ${errors.phone && "error"}`}>
              <input
                className="body-default-text"
                value={values.phone}
                onChange={handleChange("phone")}
                placeholder="Phone"
                type="text"
              />
            </div>
            <div className={`input ${errors.subject && "error"}`}>
              <input
                className="body-default-text"
                value={values.subject}
                onChange={handleChange("subject")}
                placeholder="Subject"
                type="text"
              />
            </div>
            <div className={`input big ${errors.message && "error"}`}>
              <textarea
                value={values.message}
                onChange={handleChange("message")}
                className="big body-default-text"
                placeholder="Message"
                type="text"
              />
            </div>
            <AnimationButton
              background="#1f1f1f"
              className="button-solid"
              onClick={() => {
                handleSubmit();
                handleErrors();
              }}
            >
              {loading ? "Wait.." : "Submit"}
            </AnimationButton>
          </div>
        </Col>
      </Row>
    </div>
  );
};
