import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useState } from "react";
import { Logo } from "../components/Logo";
import {
  handleScrollContact,
  handleScrollProjects,
  handleScrollService,
} from "../constants/referance";
import { useBreakpoints } from "../hooks/useBreakpoints";
import { AnimationButton } from "../components/AnimationButton";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
export const HeaderWidget = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false); // Define the state and the setter function

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const { lg } = useBreakpoints();
  const variants = {
    whileHover: { color: "#4173FD" },
  };

  return (
    <>
      <Container style={{ position: "relative", zIndex: 10 }}>
        <Navbar
          expand={"lg"}
          style={{ position: "absolute", width: "100%" }}
          className=" mb-3 pt-4"
        >
          <Container fluid>
            <Navbar.Brand href="/">
              <Logo />
            </Navbar.Brand>
            {!lg && !showMenu && (
              <CustomToggle onClick={() => setShowMenu(true)} />
            )}
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="end"
              show={showMenu}
              onHide={handleCloseMenu}
            >
              <Offcanvas.Header
                closeButton
                closeVariant="white"
                className="mt-4"
              >
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-lg`}
                ></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="mt-4">
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link>
                    <motion.div
                      // {...variants}
                      variants={variants}
                      whileHover={"whileHover"}
                      className="body-default-text cursor-pointer nav-item"
                      onClick={() => {
                        // handleScrollAbout();
                        handleCloseMenu();
                        navigate("/");
                      }}
                    >
                      Home
                    </motion.div>
                  </Nav.Link>
                  <Nav.Link>
                    <motion.div
                      variants={variants}
                      whileHover={"whileHover"}
                      className="body-default-text cursor-pointer nav-item"
                      onClick={() => {
                        // handleScrollAbout();
                        handleCloseMenu();
                        navigate("/about");
                      }}
                    >
                      About Us
                    </motion.div>
                  </Nav.Link>
                  <Nav.Link>
                    <motion.div
                      variants={variants}
                      whileHover={"whileHover"}
                      className="body-default-text cursor-pointer nav-item"
                      onClick={() => {
                        navigate("/");
                        handleCloseMenu();

                        setTimeout(() => {
                          handleScrollProjects();
                        }, 500);
                      }}
                    >
                      Projects
                    </motion.div>
                  </Nav.Link>
                  <Nav.Link>
                    <motion.div
                      variants={variants}
                      whileHover={"whileHover"}
                      className="body-default-text cursor-pointer nav-item"
                      onClick={() => {
                        navigate("/");
                        handleCloseMenu();

                        setTimeout(() => {
                          handleScrollService();
                        }, 500);
                      }}
                    >
                      Services
                    </motion.div>
                  </Nav.Link>
                  <Nav.Link className={lg ? "mt-1" : ""}>
                    {lg ? (
                      <AnimationButton
                        onClick={() => {
                          handleScrollContact();
                        }}
                      >
                        Contact Us
                      </AnimationButton>
                    ) : (
                      <div
                        className={"body-default-text cursor-pointer nav-item"}
                        onClick={() => {
                          handleCloseMenu();

                          handleScrollContact();
                        }}
                      >
                        Contact Us
                      </div>
                    )}
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </Container>
    </>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div onClick={onClick} style={{ cursor: "pointer", transform: "scaleX(-1)" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
    >
      <path
        d="M30.0995 20.5625H11.2604C10.4492 20.5625 9.79163 19.9049 9.79163 19.0938C9.79163 18.2826 10.4492 17.625 11.2604 17.625H30.0995C30.9107 17.625 31.5683 18.2826 31.5683 19.0938C31.5683 19.9049 30.9107 20.5625 30.0995 20.5625Z"
        fill="white"
      />
      <path
        d="M11.2604 27.4167H36.3662C37.1774 27.4167 37.835 28.0742 37.835 28.8854C37.835 29.6966 37.1774 30.3542 36.3662 30.3542H11.2604C10.4492 30.3542 9.79163 29.6966 9.79163 28.8854C9.79163 28.0742 10.4492 27.4167 11.2604 27.4167Z"
        fill="white"
      />
    </svg>
  </div>
));
