import { motion } from "framer-motion";

const delay1 = 1.5;
const delay2 = 1;
const delay3 = delay2 + 1;
export const MissionValueAbout = () => {
  return (
    <>
      <svg
        width="100%"
        height={"100%"}
        // height="677"
        viewBox="0 0 1230 677"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <motion.text
          fill="white"
          y="80"
          x="188"
          dx={0}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 3, delay: delay1 } }}
        >
          <tspan
            fill="white"
            dy={"0"}
            x="188"
            dx={-75}
            fontSize={50}
            fontWeight={600}
          >
            Our Mission
          </tspan>
        </motion.text>

        <motion.text
          fill="white"
          y="405"
          x="188"
          dx={0}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 3, delay: delay2 } }}
        >
          <tspan
            fill="white"
            dy={"0"}
            x="188"
            dx={-46}
            fontSize={50}
            fontWeight={600}
          >
            Our Values
          </tspan>
        </motion.text>

        <foreignObject width={625} height="10cm" x={186} y={109}>
          <motion.div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: 16,
              color: "#959595",
              textAlign: "justify",
              fontWeight: 300,
              lineHeight: 1.8,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 3, delay: delay2 } }}
          >
            At TetLabs, our seasoned blockchain experts pioneer ingenious
            solutions across blockchain development, smart contracts,
            decentralized applications (dApps), and more. Our success stories
            span finance, healthcare, and supply chain management, showcasing
            our commitment to driving innovation.
          </motion.div>
        </foreignObject>

        <foreignObject width={625} height="10cm" x={214} y={432}>
          <motion.div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: 16,
              color: "#959595",
              textAlign: "justify",
              fontWeight: 300,
              lineHeight: 1.8,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 3, delay: delay2 } }}
          >
            Innovation, excellence, collaboration, integrity, impact, and
            customer centricity are the fuels of our journey. Trust,
            transparency, and teamwork drive our success, propelling our clients
            to new heights. <br /> Join us and let's dive into the thrilling
            realm of blockchain innovation together.
          </motion.div>
        </foreignObject>

        <motion.rect
          x="169"
          y="115"
          width="1"
          height="59"
          fill="white"
          fillOpacity="0.5"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 2, delay: delay1 } }}
        />

        <motion.rect
          x="195"
          y="440"
          width="1"
          height="59"
          fill="white"
          fillOpacity="0.5"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 2, delay: delay2 } }}
        />

        <mask
          id="mask0_361_184"
          //   style="mask-type:alpha"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="11"
          y="5"
          width="952"
          height="283"
        >
          <path
            d="M856 287.631C835 173.631 919.333 70.7979 963 36.6312V5.63123H11V287.631H856Z"
            fill="#D9D9D9"
          />
        </mask>

        <motion.path
          d="M944.5 1.5H21C9.95431 0 1 10.4543 1 21.5V167C1 178.046 9.95431 187 21 187H51.5C62.5457 187 71.5 195.954 71.5 207V241C71.5 252.046 80.4543 261 91.5 261H800"
          stroke="url(#paint0_linear_388_159)"
          strokeWidth="2"
          transform="translate(53   ,0)"
          initial={{ pathLength: 0 }}
          animate={{
            pathLength: 1,
            transition: { duration: 2, delay: delay2 },
          }}
        />
        <defs>
          <linearGradient
            id="paint0_linear_388_159"
            x1="756"
            y1="91.4992"
            x2="24.4998"
            y2="234.499"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#32D5FD" />
            <stop offset="1" stopColor="#4173FD" />
          </linearGradient>
        </defs>

        <motion.path
          d="M1135.5 48V179C1138.5 190.046 1129.55 199 1118.5 199H1076.5C1065.45 199 1056.5 207.954 1056.5 219V257.5C1056.5 268.546 1047.55 277.5 1036.5 277.5H21.5C10.4543 277.5 1.5 268.546 1.5 257.5V21C1.5 9.95431 10.4543 1 21.5 1H800"
          stroke="url(#paint0_linear_388_161)"
          strokeWidth="2"
          transform="translate(53 ,315)"
          initial={{ pathLength: 0 }}
          animate={{
            pathLength: 1,
            transition: { duration: 2, delay: delay3 },
          }}
        />
        <defs>
          <linearGradient
            id="paint0_linear_388_161"
            x1="1.5"
            y1="139.249"
            x2="1138.5"
            y2="139.249"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#32D5FD" />
            <stop offset="1" stopColor="#4173FD" />
          </linearGradient>
        </defs>

        <mask
          id="mask1_361_184"
          //   style="mask-type:alpha"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="301"
          width="1192"
          height="376"
        >
          <path
            d="M919 443C1058.2 487.8 1159 387 1192 331V677H0V301H840C846 321 860 418 919 443Z"
            fill="#D9D9D9"
          />
        </mask>

        <motion.image
          x={800}
          id="image0_361_184"
          width="429"
          height="447"
          href="assets/img/about/mission_image.png"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 2, delay: delay1 } }}
        />
        <defs>
          <linearGradient
            id="paint0_linear_361_184"
            x1="597.401"
            y1="11.8357"
            x2="597.401"
            y2="270.954"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#32D5FD" />
            <stop offset="1" stopColor="#4173FD" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_361_184"
            x1="31.5"
            y1="470.315"
            x2="1169"
            y2="470.315"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#32D5FD" />
            <stop offset="1" stopColor="#4173FD" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};
