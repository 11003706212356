import { ToastContainer } from "react-toastify";
import { LandingPage } from "./pages/landing-page/LandingPage";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AboutPage } from "./pages/about-page/AboutPage";

function App() {
  return (
    <>
      <ToastContainer limit={1} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
