import { memo, useEffect, useMemo, useRef, useState } from "react";
// import { LottiePlayer as Lottie } from "lottie-react";
import Lottie from "lottie-react";

import axios from "axios";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { isIOS, isMacOs, isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { Loader } from "./Loader";

let isStartVideo = false;

export const Player = ({ direction, data }) => {
  const divRef = useRef();
  const lottieRef = useRef();
  const startIn = useMemo(() => {
    if (isMobile && isIOS) return 0.02;
    else if (isMobile) return 0.3;
    else return 0.2;
  }, []);

  const { scrollYProgress } = useScroll({
    target: divRef,
    offset: ["start end", "end start"],
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest > startIn && isStartVideo == false) {
      lottieRef.current.setSpeed(1);
      lottieRef.current.setDirection(direction);
      lottieRef.current.play();
    }
  });

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.pause();
    }
  }, [lottieRef.current]);

  return (
    <div ref={divRef} style={{ width: "100%" }}>
      <Lottie
        animationData={data}
        lottieRef={lottieRef}
        loop={false}
        autoPlay={false}
        muted
      />
    </div>
  );
};

const Video = memo(({ data }) => {
  const videoRef = useRef();

  const startIn = useMemo(() => {
    if (isMobile && isIOS) return 0.02;
    else if (isMobile) return 0.3;
    else return 0.2;
  }, []);
  const { scrollYProgress } = useScroll({
    target: videoRef,
    offset: ["start end", "end start"],
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest > startIn && isStartVideo == false) {
      isStartVideo = true;
      videoRef.current.play();
    } else if (
      videoRef.current.currentTime < 4.5 &&
      latest > startIn &&
      videoRef.current.paused == true
    ) {
      // videoRef.current.play();
    }
  });

  useEffect(() => {
    if (isIOS && videoRef.current) {
      setTimeout(() => {
        videoRef.current.play();

        setTimeout(() => {
          videoRef.current.pause();
        }, 400);
      }, 500);
    }
  }, [videoRef.current]);

  return (
    <>
      <video
        style={{ width: "100%" }}
        ref={videoRef}
        muted
        loop={false}
        preload="auto"
        autoPlay={false}
        playsInline
      >
        <source src={data} />
      </video>
    </>
  );
});

export const LottiePlayer = memo(({ jsonSrc, videoSrc }) => {
  const location = useLocation();
  useEffect(() => {
    isStartVideo = false;
  }, [location]);

  const [jsonBlob, setJsonBlob] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);

  const handleDownloadVideo = async () => {
    if (isMacOs || isIOS) {
      let file = await axios.get(jsonSrc);
      setJsonBlob(file.data);
    } else {
      let file = await axios.get(videoSrc, {
        responseType: "blob",
      });

      const url = URL.createObjectURL(file.data);
      setVideoBlob(url);
    }
  };

  useEffect(() => {
    handleDownloadVideo();
  }, []);

  if (jsonBlob == null && videoBlob == null) return <Loader />;
  else if (jsonBlob != null) return <Player data={jsonBlob} direction={1} />;
  else return <Video data={videoBlob} />;
});
