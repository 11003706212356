export let serviceRef = null;
export let setServiceRef = (ref) => (serviceRef = ref);
export const handleScrollService = () => serviceRef.scrollIntoView();

export let projectsRef = null;
export let setProjectsRef = (ref) => (projectsRef = ref);
export const handleScrollProjects = () => projectsRef.scrollIntoView();

export let aboutRef = null;
export let setAboutRef = (ref) => (aboutRef = ref);
export const handleScrollAbout = () => aboutRef.scrollIntoView();

export let contactRef = null;
export let setContactRef = (ref) => (contactRef = ref);
export const handleScrollContact = () => contactRef.scrollIntoView();

export let homeRef = null;
export let setHomeRef = (ref) => (homeRef = ref);
export const handleScrollHome = () => homeRef.scrollIntoView();
