import axios from "axios";

const api = axios.create({
  baseURL: "https://tetlabs.io/",
  timeout: 200000,
  headers: {
    // "Content-Type": "multipart/form-data",
    // 'Content-Type': 'application/x-www-form-urlencoded'
    Accept: "application/json",
    // "Access-Control-Allow-Origin": "*",

    // "Access-Control-Allow-Methods": "POST, PUT, PATCH, GET, DELETE, OPTIONS",

    // "Access-Control-Allow-Headers": "*",
  },
});

export { api };
