import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { serviceRef } from "../../constants/referance";

export const BackgoundLines = () => {
  const [top, setTop] = useState();
  const [top2, setTop2] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      setTop(document.getElementById("service_blockchain").offsetTop + 350);
      setTop2(document.getElementById("project_slider").offsetTop + 250);
    }, 1000);
  }, []);

  return (
    <>
      {!isMobile && (
        <div
          className="logo-lines"
          style={{ backgroundImage: `url(${"assets/svg/lines.svg"})` }}
        />
      )}
      {!isMobile && top && (
        <div
          className="logo-lines"
          style={{
            backgroundImage: `url(${"assets/svg/lines-2.svg"})`,
            // top: serviceRef.getBoundingClientRect().top,
            top: top,
          }}
        />
      )}
      {!isMobile && top2 && (
        <div
          className="logo-lines"
          style={{
            backgroundImage: `url(${"assets/svg/lines-2.svg"})`,
            // top: serviceRef.getBoundingClientRect().top,
            top: top2,
          }}
        />
      )}
    </>
  );
};
