import { HeaderWidget } from "../../widgets/HeaderWidget";
import { Container } from "react-bootstrap";
import { Break } from "../../components/Break";
import { FooterWidget } from "../../widgets/FooterWidget";
import { EmailWidget } from "../../widgets/EmailWidget";
import { TextWidget } from "../../widgets/TextWidget";
import { IntroductionSection } from "./IntroductionSection";
import { ServiceSection } from "./ServiceSection";
import { ProjectSlider } from "./ProjectSlider";
import { LogoSection } from "./LogoSection";
import { SocialMedia } from "../../components/SocialMedia";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { BackgoundLines } from "./BackgoundLines";
import { memo, useEffect } from "react";
import { useLocation } from "react-use";
export const LandingPage = memo(() => {
  const { lg } = useBreakpoints();

  // useEffect(() => {
  //   console.log(serviceRef.getBoundingClientRect());
  // }, []);
  const location = useLocation();
  useEffect(() => {
    window.scroll({ top: 0, behavior: "instant" });
  }, [location]);
  return (
    <div id="landing-page-styles">
      {/* <img className="logo-lines" src="assets/svg/lines.svg" /> */}
      {lg && (
        <div style={{ position: "absolute", bottom: "20%", left: 20 }}>
          <SocialMedia flexDirection="column" />
        </div>
      )}

      <BackgoundLines />

      <Container>
        <HeaderWidget />
        <LogoSection />
        <Break height={92} mobileHeight={50} />
        <IntroductionSection />
        <Break height={296} mobileHeight={60} />
        <ServiceSection />
        <Break height={150} mobileHeight={60} />
        <ProjectSlider />
        <Break height={280} mobileHeight={60} />
        <TextWidget />
        <Break height={300} mobileHeight={60} />
        <EmailWidget />
        <Break height={150} mobileHeight={100} />
        <FooterWidget />
      </Container>
    </div>
  );
});
