import { Col, Row } from "react-bootstrap";

export const TextWidget = () => {
  // export const TextWidget = (head, description) => {

  return (
    <div style={{ textAlign: "center" }}>
      <Row className="justify-content-center">
        <Col xxl={8} xl={9} md={12} sm={12} xs={12}>
          <p className="head-2 pb-3">Integrating The Future with Ease</p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xxl={7} xl={8} md={12} sm={12} xs={12}>
          <p className="gray-text">
            At Tetlabs, we believe that we should not wait for the future, we
            rather make it happen. 
            <br className="d-none d-lg-block d-xl-block d-xxl-block" />
            Using blockchain technology to level-up any project.
          </p>
        </Col>
      </Row>
    </div>
  );
};
