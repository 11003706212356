import { motion } from "framer-motion";
export const AboutViewMobile = () => {
  return (
    <>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 430 1076"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g filter="url(#filter0_f_409_156)">
          <path
            d="M169.222 741.615C119.448 647.463 120.987 639.533 127.979 647.337L2.31667 469.36C51.2383 496.056 151.607 548.707 161.707 545.741C174.332 542.033 185.229 554.407 191.514 570.059C197.799 585.712 202.615 612.47 220.615 641.831C238.614 671.192 300.253 596.221 377.531 646.67C454.809 697.119 348.156 868.777 306.522 781.962C273.215 712.511 278.462 729.892 285.25 747.263C267.313 784.611 218.997 835.768 169.222 741.615Z"
            fill="#4173FD"
            fillOpacity="0.35"
          />
        </g>
        <g filter="url(#filter1_f_409_156)">
          <path
            d="M264.669 336.274C300.877 442.005 298.299 449.38 292.484 439.851L391.931 648.14C347.486 608.872 256.018 530.389 245.701 530.606C232.806 530.877 223.771 515.82 219.705 498.774C215.64 481.727 214.51 454.169 200.785 420.536C187.061 386.903 116.455 444.067 47.3625 373.908C-21.73 303.75 106.081 163.664 135.283 260.017C158.645 337.099 155.83 318.633 151.503 299.764C174.138 267.88 228.461 230.543 264.669 336.274Z"
            fill="#32D5FD"
            fillOpacity="0.35"
          />
        </g>
        <path
          d="M62 472.603V74.8975C62 68.3267 67.3267 63 73.8975 63H362.342C368.913 63 374.239 68.3266 374.239 74.8974V381.951C374.239 394.653 363.942 404.951 351.239 404.951H339.4C312.946 404.951 291.5 426.396 291.5 452.851V461.5C291.5 474.203 281.203 484.5 268.5 484.5H223H73.8975C67.3267 484.5 62 479.173 62 472.603Z"
          stroke="url(#paint0_linear_409_156)"
          strokeWidth="2"
        />
        <path
          d="M374.239 653L374.239 1062.6C374.239 1069.17 368.913 1074.5 362.342 1074.5L73.8973 1074.5C67.3266 1074.5 61.9999 1069.17 61.9999 1062.6L61.9999 713C61.9999 700.297 72.2974 690 84.9999 690L96.8393 690C123.294 690 144.739 668.554 144.739 642.1L144.739 628.212C144.739 618.403 152.691 610.451 162.5 610.451V610.451"
          stroke="url(#paint1_linear_409_156)"
          strokeWidth="2"
        />

        <text color="white" fill="white" y={252} x={80}>
          <tspan fontSize={38} fontWeight={700}>
            WELCOME
          </tspan>
          <tspan dy={37} x={82} fontSize={38} fontWeight={700}>
            TO
          </tspan>
          <tspan dy={0} dx={9} fontSize={38} fontWeight={700} fill="#4173FD">
            TETLABS
          </tspan>

          <tspan x={87} dy={23} fontSize={10} fontWeight={600}>
            Exploring the Infinite Horizons of Blockchain
          </tspan>
          <tspan x={87} dy={15} fontSize={10} fontWeight={600}>
            Technology
          </tspan>
        </text>

        <rect
          x="95"
          y="342"
          width="0.518142"
          height="30.5704"
          fill="white"
          fillOpacity="0.5"
        />

        <foreignObject width={235} height="10cm" x={105} y={340}>
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: 9,
              color: "#959595",
              textAlign: "justify",
              fontWeight: 300,
              letterSpacing: 0.249,
              lineHeight: 1.2,
            }}
          >
            Embark on a journey with TetLabs, a gathering of passionate
            blockchain enthusiasts. We're here to unravel the limitless
            potential of blockchain technology, reshaping industries and
            creating new horizons.
          </div>
        </foreignObject>

        <mask
          id="mask0_409_156"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="323"
          y="438"
          width="35"
          height="35"
        >
          <motion.path
            d="M334.925 458.992V471.694C335.216 473.398 336.772 472.789 336.772 472.789L344.89 470.636C346.155 469.635 346.009 468.736 346.009 468.736L346.055 455.921L334.925 458.973V458.992ZM324.378 447.488C324.132 447.6 324.023 447.787 324.023 447.787C323.959 447.918 323.941 448.04 323.941 448.087C323.895 448.864 323.813 454.63 323.795 455.566C323.75 457.681 323.768 459.357 323.841 460.667C323.841 460.667 324.378 462.071 325.733 461.519C327.162 461.126 330.939 460.087 334.916 459.001V444.511C329.01 446.13 324.687 447.347 324.378 447.488ZM354.901 439.007C354.664 439.073 350.896 440.112 346.055 441.441V455.94L355.292 453.404C355.292 453.404 356.894 452.898 357.094 451.4V440.205C357.094 440.205 356.985 438.895 355.638 438.895C355.428 438.895 355.183 438.932 354.91 439.007"
            fill="white"
            initial={{ rotateY: 0 }}
            animate={{
              rotateY: 1080,
              transition: {
                duration: 1.5,
                ease: "easeOut",
                repeatDelay: 3,
                repeat: Infinity,
              },
            }}
          />
        </mask>
        <g mask="url(#mask0_409_156)">
          <path
            d="M357.085 438.895H323.75V473.398H357.085V438.895Z"
            fill="#4173FD"
          />
        </g>

        <circle
          cx="340"
          cy="455"
          r="33.4086"
          stroke="#4173FD"
          strokeWidth="1.18279"
        />

        <foreignObject width={235} height="10cm" x={97} y={750}>
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: 28,
              fontWeight: 700,
              color: "white",
            }}
          >
            Our Mission
          </div>
        </foreignObject>
        <foreignObject width={235} height="10cm" x={105} y={750 + 50}>
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: 9,
              color: "#959595",
              textAlign: "justify",
              fontWeight: 300,
              letterSpacing: 0.249,
              lineHeight: 1.2,
            }}
          >
            At TetLabs, our seasoned blockchain experts pioneer ingenious
            solutions across blockchain development, smart contracts,
            decentralized applications (dApps), and more. Our success stories
            span finance, healthcare, and supply chain management, showcasing
            our commitment to driving innovation.
          </div>
        </foreignObject>

        <foreignObject width={235} height="10cm" x={97} y={750 + 50 + 115}>
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: 28,
              fontWeight: 700,
              color: "white",
            }}
          >
            Our Values
          </div>
        </foreignObject>

        <foreignObject
          width={235}
          height="10cm"
          x={105}
          y={750 + 50 + 115 + 50}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: 9,
              color: "#959595",
              textAlign: "justify",
              fontWeight: 300,
              letterSpacing: 0.249,
              lineHeight: 1.2,
            }}
          >
            Innovation, excellence, collaboration, integrity, impact, and
            customer-centricity are the fuels of our journey. Trust,
            transparency, and teamwork drive our success, propelling our clients
            to new heights. Join us and let's dive into the thrilling realm of
            blockchain innovation together.
          </div>
        </foreignObject>

        <rect
          x="95"
          y="802"
          width="0.518142"
          height="30.5704"
          fill="white"
          fillOpacity="0.5"
        />

        <rect
          x="95"
          y="968"
          width="0.518142"
          height="30.5704"
          fill="white"
          fillOpacity="0.5"
        />

        <image
          x={30}
          y={-40}
          width="270"
          height="270"
          href="assets/img/about/logo-metal-2.png"
        />
        <image
          x={110}
          y={490}
          width="300"
          height="250"
          href="assets/img/about/mission_image.png"
        />

        <defs>
          <filter
            id="filter0_f_409_156"
            x="-167.993"
            y="299.05"
            width="743.199"
            height="677.339"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
            // color-interpolation-filters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="85.1549"
              result="effect1_foregroundBlur_409_156"
            />
          </filter>
          <filter
            id="filter1_f_409_156"
            x="-143.164"
            y="56.953"
            width="705.404"
            height="761.497"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
            // color-interpolation-filters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="85.1549"
              result="effect1_foregroundBlur_409_156"
            />
          </filter>

          <linearGradient
            id="paint0_linear_409_156"
            x1="62"
            y1="273.749"
            x2="374.239"
            y2="273.749"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#32D5FD" />
            <stop offset="1" stopColor="#4173FD" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_409_156"
            x1="374.239"
            y1="842.477"
            x2="61.9999"
            y2="842.477"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#32D5FD" />
            <stop offset="1" stopColor="#4173FD" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};
