import { Col, Row } from "react-bootstrap";
import { VideoContainer } from "../../components/Video";
import { Break } from "../../components/Break";
import { setAboutRef } from "../../constants/referance";
import { isBrowser, isSafari } from "react-device-detect";
import { memo } from "react";
import { LottiePlayer } from "../../components/LottiePlayer";

export const IntroductionSection = memo(() => {
  return (
    <Row ref={(ref) => setAboutRef(ref)} className="introduction-tetlabs">
      <Col lg={6} className="d-flex justify-content-center align-items-center">
        <LottiePlayer
          direction={1}
          jsonSrc={"assets/lottie/tetris.lottie.json"}
          videoSrc="assets/videos/tetris.webm"
        />
      </Col>

      <Col lg={{ span: 6, offset: 0 }} xs={{ span: 11, offset: 1 }}>
        <div className="introduction-content d-flex flex-column justify-content-center">
          <img
            className="introduction-logo"
            src="assets/img/TetlabsBlockchain-2.png"
          />
          <Break />
          <div
            className="justify-text body-default-text"
            style={{ marginRight: 50, fontWeight: 700 }}
          >
            <span className="blue-text"> Welcome to TetLabs: </span>Exploring
            the Infinite Horizons of Blockchain Technology
          </div>
          <Break />
          <div
            className="justify-text body-default-text gray-text d-flex"
            style={{ marginRight: 50 }}
          >
            <div className="text-column " />
            Embark on a journey with TetLabs, a gathering of passionate
            blockchain enthusiasts. We're here to unravel the limitless
            potential of blockchain technology, reshaping industries and
            creating new horizons.
          </div>
          <Break />
          {/* <div className="button-solid">Learn More</div> */}
        </div>
      </Col>
    </Row>
  );
});
