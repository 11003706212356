import { motion, useAnimate } from "framer-motion";
export const AnimationButton = ({
  children,
  onClick,
  background = "#000000FF",
}) => {
  const [scope, animate] = useAnimate();

  const startHover = () => {
    animate(scope.current.children[0], { background: "#00000000" });
  };

  const endHover = () => {
    animate(scope.current.children[0], { background });
  };
  return (
    <motion.div
      onClick={onClick}
      ref={scope}
      onPointerEnter={startHover}
      onPointerLeave={endHover}
      className=""
      style={{
        background: "linear-gradient(90deg, #32d5fd 0%, #4173fd 100%)",
        borderRadius: 50,
        padding: 2,
        cursor: "pointer",
        width: "fit-content",
      }}
    >
      <motion.div
        style={{
          background,
          borderRadius: 50,
          padding: "12px 42px",
          color: "white",
        }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};
