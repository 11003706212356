import { socialmedia } from "../constants/socialmedia";

export const SocialMedia = ({ flexDirection = "row" }) => {
  return (
    <div className="d-flex gap-4" style={{ flexDirection }}>
      {socialmedia.map((item, index) => (
        <a href={item.link} key={index} target="_blank">
          <item.component />
        </a>
      ))}
    </div>
  );
};
