import { motion } from "framer-motion";
export const Loader = () => {
  return (
    <>
      {/* <svg
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <mask
          id="mask0_315_379"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="50"
          y="50"
          width="39"
          height="39"
        >
          <motion.path
            d="M1165.33 481.093V495.289C1165.65 497.193 1167.39 496.513 1167.39 496.513L1176.47 494.107C1177.88 492.988 1177.72 491.983 1177.72 491.983L1177.77 477.661L1165.33 481.072V481.093ZM1153.54 468.235C1153.26 468.361 1153.14 468.57 1153.14 468.57C1153.07 468.716 1153.05 468.852 1153.05 468.905C1153 469.773 1152.91 476.217 1152.89 477.264C1152.84 479.628 1152.86 481.501 1152.94 482.965C1152.94 482.965 1153.54 484.534 1155.06 483.917C1156.65 483.478 1160.87 482.317 1165.32 481.103V464.908C1158.72 466.718 1153.89 468.078 1153.54 468.235ZM1187.65 458.757C1187.39 458.83 1183.18 459.991 1177.77 461.477V477.682L1188.09 474.847C1188.09 474.847 1189.88 474.282 1190.1 472.608V460.096C1190.1 460.096 1189.98 458.631 1188.48 458.631C1188.24 458.631 1187.97 458.673 1187.66 458.757"
            fill="white"
            initial={{ rotateY: 0 }}
            animate={{
              rotateY: 1080,
              transition: {
                duration: 1.5,
                ease: "easeOut",
                repeatDelay: 3,
                repeat: Infinity,
              },
            }}
          />
        </mask>
        <g mask="url(#mask0_315_379)">
          <path
            d="M1190.09 458.631H1152.84V497.193H1190.09V458.631Z"
            fill="#4173FD"
          />
        </g>

        <motion.circle
          cx="50"
          cy="50"
          r="37.339"
          stroke="#4173FD"
          strokeWidth="1.32194"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1, transition: { duration: 2 } }}
        />
      </svg> */}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        height="120"
        viewBox="0 0 76 76"
        fill="none"
        style={{ margin: 30 }}
      >
        <mask
          id="mask0_372_14249"
          //   style="mask-type:luminance"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="19"
          y="20"
          width="39"
          height="38"
        >
          <motion.path
            d="M32.3279 42.4613V56.6579C32.6533 58.562 34.3926 57.8819 34.3926 57.8819L43.4652 55.4757C44.879 54.3563 44.7163 53.352 44.7163 53.352L44.7672 39.0299L32.3279 42.4404V42.4613ZM20.5395 29.6039C20.2649 29.7294 20.1428 29.9386 20.1428 29.9386C20.0716 30.0851 20.0513 30.2211 20.0513 30.2734C20.0004 31.1417 19.9089 37.5862 19.8886 38.6324C19.8377 40.9967 19.8581 42.8694 19.9394 44.334C19.9394 44.334 20.5395 45.9033 22.055 45.286C23.6519 44.8466 27.8729 43.6854 32.3177 42.4718V26.277C25.7166 28.0869 20.8853 29.4469 20.5395 29.6039ZM54.6535 20.1255C54.389 20.1988 50.1782 21.36 44.7672 22.8456V39.0508L55.0909 36.2157C55.0909 36.2157 56.881 35.6508 57.1047 33.9769V21.4646C57.1047 21.4646 56.9827 20 55.4774 20C55.2434 20 54.9688 20.0418 54.6637 20.1255"
            fill="white"
            initial={{ rotateY: 0 }}
            animate={{
              rotateY: 1080,
              transition: {
                duration: 1.5,
                ease: "easeOut",
                repeatDelay: 3,
                repeat: Infinity,
              },
            }}
          />
        </mask>
        <g mask="url(#mask0_372_14249)">
          <path d="M57.0945 20H19.8376V58.562H57.0945V20Z" fill="#4173FD" />
        </g>
        <circle
          cx="38"
          cy="38"
          r="37.339"
          stroke="#4173FD"
          strokeWidth="1.32194"
        />
      </svg>
    </>
  );
};
