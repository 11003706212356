export const Logo = ({ width = 53, height = 52 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 53 52"
      fill="none"
    >
      <mask
        id="mask0_72_14576"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="53"
        height="52"
      >
        <path
          d="M17.5476 30.7366V50.1635C18.0057 52.769 20.4537 51.8385 20.4537 51.8385L33.2236 48.5458C35.2136 47.0139 34.9845 45.6396 34.9845 45.6396L35.0561 26.0409L17.5476 30.7079V30.7366ZM0.955253 13.1421C0.568719 13.3139 0.396927 13.6003 0.396927 13.6003C0.296714 13.8007 0.268082 13.9868 0.268082 14.0584C0.196502 15.2466 0.0676572 24.0653 0.039025 25.4969C-0.0325552 28.7323 -0.00392313 31.2949 0.110605 33.2992C0.110605 33.2992 0.955253 35.4466 3.08835 34.6019C5.33597 34.0006 11.2771 32.4116 17.5332 30.7509V8.58964C8.24213 11.0663 1.442 12.9274 0.955253 13.1421ZM48.9713 0.171793C48.5991 0.272005 42.6722 1.86109 35.0561 3.89397V26.0695L49.5869 22.1899C49.5869 22.1899 52.1065 21.4168 52.4215 19.1263V2.00425C52.4215 2.00425 52.2497 0 50.1309 0C49.8016 0 49.4151 0.0572642 48.9856 0.171793"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_72_14576)">
        <path d="M52.4071 0H-0.0326004V52.769H52.4071V0Z" fill="white" />
      </g>
    </svg>
  );
};
