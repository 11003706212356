import "../../styles/pages/about-page-styles.scss";
import { WelcomeSvg } from "../../assets/svg/about/Welcome";
import { MissionValueAbout } from "../../assets/svg/about/MissionValueAbout";
import { Container } from "react-bootstrap";
import { HeaderWidget } from "../../widgets/HeaderWidget";
import { FooterWidget } from "../../widgets/FooterWidget";
import { Break } from "../../components/Break";
import { EmailWidget } from "../../widgets/EmailWidget";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { AboutViewMobile } from "../../assets/svg/about/AboutViewMobile";
import { useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
export const AboutPage = () => {
  const { lg } = useBreakpoints();

  const ref = useRef(null);
  const isInView = useInView(ref);
  const location = useLocation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 500);
  }, [location]);
  useEffect(() => {
    if (isInView) setShow(true);
  }, [isInView]);

  if (lg)
    return (
      <div id="about-page-styles">
        <Container>
          <HeaderWidget />

          <Break height={300} mobileHeight={100} />
          <div className=" d-flex flex-column justify-content-between align-items-center">
            <img src="assets/img/TetlabsBlockChain-1.png" />
          </div>

          <Break height={150} mobileHeight={100} />
          <WelcomeSvg />
          <div style={{ height: 40 }} />

          <div ref={ref} style={{ color: "transparent" }}>
            .
          </div>
          {show && <MissionValueAbout />}
          <Break height={150} mobileHeight={50} />
          <EmailWidget />
          <Break height={150} mobileHeight={100} />
          <FooterWidget />
        </Container>
        {/* <img src={welcomeSvg} /> */}
      </div>
    );
  else
    return (
      <div>
        <Container fluid>
          <HeaderWidget />

          <Break height={300} mobileHeight={200} />
          <div
            className=" d-flex flex-column justify-content-between align-items-center"
            style={{ marginLeft: "10%", width: "80%" }}
          >
            <img
              src="assets/img/TetlabsBlockChain-1.png"
              style={{ width: "100%" }}
            />
          </div>
        </Container>

        <Break height={300} mobileHeight={100} />
        <AboutViewMobile />
        <div style={{ marginLeft: "10%", width: "80%" }}>
          <Break height={150} mobileHeight={50} />
          <EmailWidget />
          <Break height={150} mobileHeight={100} />
          <FooterWidget />
        </div>
      </div>
    );
};
