import { Col, Nav, Row } from "react-bootstrap";
import { Break } from "../components/Break";
import { useBreakpoints } from "../hooks/useBreakpoints";
import {
  handleScrollAbout,
  handleScrollContact,
  handleScrollHome,
  handleScrollProjects,
  handleScrollService,
} from "../constants/referance";
import { useLocation, useNavigate } from "react-router-dom";

export const FooterWidget = () => {
  const { lg } = useBreakpoints();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div id="footer-widget">
      <Row>
        <Col lg={6} xs={12} className=" align-items-center d-flex">
          <img
            src="assets/img/footer-logo.png"
            style={{ width: "100%", maxWidth: 350, padding: 20 }}
          />
        </Col>
        {!lg && <Break />}
        <Col lg={3} xs={6} className="d-flex flex-column gap-4">
          <Nav
            className="gray-text-500 body-large-text"
            style={{ fontWeight: 700, color: "transparent" }}
          >
            _
          </Nav>
          <div
            className="body-large-text cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </div>
          <div
            className="body-large-text cursor-pointer"
            onClick={() => {
              if (location.pathname != "/about") navigate("/about");

              window.scrollTo({ top: 0 });
            }}
          >
            About Us
          </div>
          <div
            className="body-large-text cursor-pointer"
            onClick={() => {
              if (location.pathname != "/") navigate("/");
              setTimeout(() => {
                handleScrollService();
              }, 500);
            }}
          >
            Services
          </div>
        </Col>
        <Col lg={3} xs={6} className="d-flex flex-column gap-4">
          <div className="body-large-text" style={{ color: "transparent" }}>
            _
          </div>
          <div
            className="body-large-text cursor-pointer"
            onClick={() => {
              if (location.pathname != "/") navigate("/");

              setTimeout(() => {
                handleScrollProjects();
              }, 500);
            }}
          >
            Projects
          </div>
          <div
            className="body-large-text cursor-pointer"
            onClick={() => {
              handleScrollContact();
            }}
          >
            Contact us
          </div>
        </Col>
      </Row>
      <Break height={48} />

      <Row>
        <div className="gray-text-500 body-large-text">
          ©2023 Tetlabs. All rights reserved.
        </div>
      </Row>
      <Break height={48} />
    </div>
  );
};
