import { Col, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { setProjectsRef } from "../../constants/referance";
import { AnimationButton } from "../../components/AnimationButton";

// import ExampleCarouselImage from "components/ExampleCarouselImage";

const data = [
  {
    image: "assets/img/golex.png ",
    logo: "assets/img/golexLogo.png",
    text: "Golex exchange provides secure, trusted digital asset trading and asset management services, the highest quality investment, and the most rewarding experience",
    visitWebSite: "https://golex.io",
  },
  {
    image: "assets/img/golc.png ",
    logo: "assets/img/golcoin-logo.png",
    text: "GolCoin is a cryptocurrency built on trust, with an ecosystem providing a secure and transparent way for users to transact with each other.",
    visitWebSite: "https://golc.io",
  },
  {
    image: "assets/img/minawallet.png ",
    logo: "assets/img/mena-logo.png",
    text: "Mena Wallet is a mobile cryptocurrency wallet that allows users to securely store, manage, and exchange various cryptocurrencies and tokens",
    visitWebSite: "https://gogolcoin.io/mw",
  },
  {
    image: "assets/img/gogolcoin.png ",
    logo: "assets/img/gogolcoin-logo.png",
    text: "GogolCoin is a decentralized digital currency designed to be fast, scalable, and user-friendly with low transaction fees and high privacy protection",
    visitWebSite: "https://gogolcoin.io",
  },
];

export const ProjectSlider = () => {
  const [Dimensions, setDimensions] = useState();
  function handleResize() {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    const handleUpdateData = () => {
      setTimeout(() => {
        data.map(async (el, ind) => {
          const element = await document.getElementById(`slider-image-${ind}`);
          if (element) {
            if (element.offsetHeight != 0) {
              const divHeight = element.offsetHeight;
              document.documentElement.style.setProperty(
                "--div-height",
                `${divHeight + 40}px`
              );
            }
          }
        });

        handleUpdateData();
      }, 1000);
    };

    handleUpdateData();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [Dimensions]);

  return (
    <>
      <Row ref={(ref) => setProjectsRef(ref)}>
        <Col lg={{ offset: 0 }} xs={{ offset: 1 }}>
          <div className="head pb-5">Projects</div>
        </Col>
      </Row>

      <Carousel id="project_slider" className="projects pt-5">
        {data.map((item, index) => (
          <Carousel.Item key={index}>
            <Row className="carousel-items">
              <Col xl={{ offset: 1, span: 5 }} md={6} xs={12}>
                <div id={`slider-image-${index}`}>
                  <img
                    id="slider-image"
                    className="slider-image"
                    src={item.image}
                  />
                </div>
              </Col>
              <Col xl={5} md={5} xs={12}>
                <Row className="pt-5 pb-4">
                  <Col>
                    <img className="" src={item.logo} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="gray-text">{item.text}</p>
                  </Col>
                </Row>
                <Row className="py-4">
                  <Col>
                    <AnimationButton
                      onClick={() => window.open(item.visitWebSite, "_blank")}
                    >
                      Visit Website
                    </AnimationButton>
                    {/* <a
                      style={{ textDecoration: "none" }}
                      href={item.visitWebSite}
                      target="_blank"
                    >
                      
                      <div className="button-solid">Visit Website</div>
                    </a> */}
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};
